import React, { useState } from "react"
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBed,
  faPlane,
  faCar,
  faTaxi,
} from "@fortawesome/free-solid-svg-icons"
import normalizeCityName from "../utils/normalizeCityName"

import "./bookingChecklist.css"

const getBookingCitySlug = (citySlug) => {
  const bookingCitySlugMap = {
    athenes: "athens",
    "la-valette": "valletta",
    barcelone: "barcelona",
    bruges: "brugge",
    bruxelles: "brussels",
    bucarest: "bucharest",
    copenhague: "copenhagen",
    cracovie: "krakow",
    lisbonne: "lisbon",
    londres: "london",
    nicosie: "nicosia",
    varsovie: "warsaw",
    venise: "venice",
    vienne: "vienna",
  }

  return bookingCitySlugMap[citySlug] || citySlug
}

const getAirportSlug = (citySlug) => {
  const airportSlugMap = {
    brugge: "oostende",
    nicosia: "larnaca",
  }

  return airportSlugMap[citySlug] || citySlug
}

const BookingChecklist = ({ city, countryCode }) => {
  // State to manage booking steps
  const [steps, setSteps] = useState({
    hotel: false,
    flight: false,
    car: false,
    taxi: false,
  })

  const citySlug = normalizeCityName(city)

  const bookingCitySlug = getBookingCitySlug(citySlug)

  const affiliateSlug = "?aid=397676&label=MytourofEuropeApp"

  // Function to handle clicks and open links
  const handleClick = (step) => {
    const urls = {
      hotel: `/se-loger-a-${citySlug}`,
      flight: `https://www.booking.com/flights/destination/city/${countryCode}/${getAirportSlug(
        bookingCitySlug
      )}.fr.html${affiliateSlug}`,
      car: `https://www.booking.com/cars/city/${countryCode}/${bookingCitySlug}.fr.html${affiliateSlug}`,
      taxi: `https://www.booking.com/taxi/index.fr.html${affiliateSlug}`,
    }

    if (!steps[step]) {
      if (step === "hotel") {
        navigate(urls[step])
      } else {
        window.open(urls[step], "_blank", "noopener noreferrer")
      }
    }
    setSteps({ ...steps, [step]: !steps[step] })
  }

  return (
    <div className="booking-checklist">
      <h2>Tout ce qu’il vous faut avant de partir à {city}</h2>
      <div className="checklist-grid">
        <button
          className={`checklist-button ${steps.hotel ? "checked" : ""}`}
          onClick={() => handleClick("hotel")}
        >
          <FontAwesomeIcon icon={faBed} />
          Réserver mon hébergement
        </button>
        <button
          className={`checklist-button ${steps.flight ? "checked" : ""}`}
          onClick={() => handleClick("flight")}
        >
          <FontAwesomeIcon icon={faPlane} />
          Réserver mon avion
        </button>
        <button
          className={`checklist-button ${steps.car ? "checked" : ""}`}
          onClick={() => handleClick("car")}
        >
          <FontAwesomeIcon icon={faCar} />
          Réserver ma voiture
        </button>
        <button
          className={`checklist-button ${steps.taxi ? "checked" : ""}`}
          onClick={() => handleClick("taxi")}
        >
          <FontAwesomeIcon icon={faTaxi} />
          Réserver mon taxi
        </button>
      </div>
    </div>
  )
}

export default BookingChecklist
