import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faShareAlt,
  faCopy,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faXTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons"

const ShareButton = ({ title, url }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const handleMailto = () => {
    const emailSubject = encodeURI(title + " | My tour of Europe")
    const emailBody = encodeURI(`Bonjour,
J’ai trouvé ce lien qui pourrait t’intéresser :
${url}`)
    const mailtoUrl = `mailto:?subject=${emailSubject}&body=${emailBody}`
    window.open(mailtoUrl, "_blank") || window.location.replace(mailtoUrl)
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href)
      setIsOpen(false)
      setIsCopied(true)
    } catch (err) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isCopied)
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
  }, [isCopied])

  return (
    <p>
      <button className="uppercase share" onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={faShareAlt} /> Partager
      </button>
      {isOpen && (
        <span>
          <a
            href={`https://www.facebook.com/sharer.php?u=${url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} className="share-margins" />
          </a>
          <a
            href={`https://x.com/intent/tweet?url=${url}&text=${title}&via=mytourofeurope&hashtags=mytourofeurope`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faXTwitter} className="share-margins" />
          </a>
          <button className="share share-button" onClick={handleMailto}>
            <FontAwesomeIcon icon={faEnvelope} className="share-margins" />
          </button>
          <button className="share share-button" onClick={handleCopy}>
            <FontAwesomeIcon icon={faCopy} className="share-margins" />
          </button>
        </span>
      )}
      {isCopied && <strong className="share-margins">Lien copié !</strong>}
    </p>
  )
}

export default ShareButton
