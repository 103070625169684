import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Footer from "../components/footer"
import Seo from "../components/seo"
import ShareButton from "../components/shareButton"
import BookingChecklist from "../components/bookingChecklist"

export function Head({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark
  const title = frontmatter.title
  const description = frontmatter.description
  const path = frontmatter.path
  return (
    <Seo
      title={title}
      description={description}
      slug={path}
      image={`/thumbs/incontournables${path}.png`}
    />
  )
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const title = frontmatter.title
  const city = frontmatter.city
  const countryCode = frontmatter.countryCode
  const path = frontmatter.path
  const url = `${process.env.GATSBY_BASEURL}${path}`
  return (
    <Layout content="blog">
      <article>
        <div className="blog-post-container">
          <div className="blog-post">
            <header>
              <div>
                <h1>{title}</h1>
              </div>
            </header>
            <aside>
              <ShareButton title={title} url={url} />
            </aside>
            <main>
              <section>
                <BookingChecklist city={city} countryCode={countryCode} />
              </section>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </main>
          </div>
        </div>
      </article>
      <Footer city={city} />
    </Layout>
  )
}
export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        path
        title
        description
        city
        countryCode
      }
    }
  }
`
